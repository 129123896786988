import React from 'react';

const IconLoader = () => (
  

  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="30%" height="30%" viewBox="-4 -4 120 150">

    <symbol id="rp" viewBox="-20 -10 120 80">
          <path
            d="M 42.245 57.63 L 34.68 61.2 L 27.285 45.05 Q 25.07 40.284 21.953 37.691 A 13.779 13.779 0 0 0 20.23 36.465 A 16.704 16.704 0 0 0 15.554 34.593 Q 13.465 34.085 11.02 33.958 A 32.17 32.17 0 0 0 9.35 33.915 L 8.075 33.915 L 8.075 60.18 L 0 60.18 L 0 0.85 Q 4.59 0.425 8.628 0.213 Q 12.665 0 17.425 0 A 43.723 43.723 0 0 1 21.925 0.22 Q 24.139 0.449 26.069 0.918 A 25.326 25.326 0 0 1 26.945 1.148 A 23.243 23.243 0 0 1 30.417 2.413 A 16.925 16.925 0 0 1 33.745 4.42 A 14.664 14.664 0 0 1 36.947 7.828 A 13.489 13.489 0 0 1 37.867 9.435 Q 39.27 12.325 39.27 15.81 A 16.898 16.898 0 0 1 38.717 20.247 A 12.471 12.471 0 0 1 35.572 25.798 Q 31.875 29.58 24.905 31.45 A 21.178 21.178 0 0 1 27.685 32.952 A 19.282 19.282 0 0 1 28.05 33.193 A 14.815 14.815 0 0 1 30.286 35.062 A 16.952 16.952 0 0 1 30.77 35.573 Q 32.045 36.975 33.278 38.93 Q 34.269 40.503 35.289 42.627 A 56.204 56.204 0 0 1 35.785 43.69 L 42.245 57.63 Z M 8.075 7.14 L 8.075 27.115 L 17.425 27.115 Q 22.516 27.115 26.019 25.233 A 12.707 12.707 0 0 0 27.37 24.395 A 8.745 8.745 0 0 0 31.115 18 A 12.335 12.335 0 0 0 31.195 16.575 A 8.34 8.34 0 0 0 28.413 10.231 A 11.282 11.282 0 0 0 27.625 9.563 A 11.834 11.834 0 0 0 23.916 7.674 Q 21.154 6.8 17.425 6.8 Q 14.535 6.8 12.24 6.885 Q 9.945 6.97 8.075 7.14 Z"
          />
           <path 
            transform="translate(50 0)"
            
            d="M 0 60.18 L 0 0.85 Q 3.57 0.425 7.693 0.213 Q 11.815 0 16.405 0 A 38.47 38.47 0 0 1 21.478 0.319 A 28.567 28.567 0 0 1 26.265 1.36 Q 30.6 2.72 33.575 5.1 Q 36.55 7.48 38.08 10.838 A 17.25 17.25 0 0 1 39.608 17.831 A 19.84 19.84 0 0 1 39.61 18.105 A 21.153 21.153 0 0 1 39.124 22.731 A 16.975 16.975 0 0 1 37.783 26.563 Q 35.955 30.26 32.683 32.81 A 21.313 21.313 0 0 1 27.82 35.626 A 26.76 26.76 0 0 1 24.82 36.678 A 33.695 33.695 0 0 1 18.717 37.81 A 42.215 42.215 0 0 1 14.705 37.995 A 88.42 88.42 0 0 1 10.85 37.915 A 70.489 70.489 0 0 1 8.075 37.74 L 8.075 60.18 L 0 60.18 Z M 8.075 7.14 L 8.075 30.855 Q 9.01 30.972 10.206 31.069 A 80.886 80.886 0 0 0 11.348 31.153 Q 12.991 31.262 15.043 31.278 A 90.88 90.88 0 0 0 15.725 31.28 Q 20.91 31.28 24.474 29.738 A 12.811 12.811 0 0 0 27.497 27.923 A 10.922 10.922 0 0 0 31.577 19.934 A 15.23 15.23 0 0 0 31.62 18.785 A 12.426 12.426 0 0 0 30.993 14.747 A 10.311 10.311 0 0 0 27.667 10.03 Q 24.571 7.5 19.311 6.952 A 29.709 29.709 0 0 0 16.235 6.8 A 111.456 111.456 0 0 0 13.466 6.833 A 93.447 93.447 0 0 0 11.9 6.885 A 77.583 77.583 0 0 0 9.45 7.025 A 63.784 63.784 0 0 0 8.075 7.14 Z"
          />
          </symbol>
        <title>Loader Logo</title>
        <g  fill="none" 
            stroke="currentColor"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="arcs">
          <path id="hex"
            d="M 50, 5
                      L 11, 27
                      L 11, 72
                      L 50, 95
                      L 89, 73
                      L 89, 28 z"
          />
          
          
        <use href="#rp" x="11" y="27" width="78" height="47.5"  />
        </g>
      </svg>

);

export default IconLoader;
